import { computed, defineAsyncComponent, ref, shallowRef, watch } from 'vue';

import { FILTERS_STORAGE_KEY } from '../../elements/filter';
import { AxiosError } from 'axios';
import { i18n } from '@/services/i18n';
import { useForm } from '@/services/form';
import { api } from '@/services/api';
import { storage } from '@/services/storage';

import type { Reservation } from '@/types/location';
import type { Tab, ReserveForm, StoredReserve } from './types';
import type { FiltersForm } from '../../elements/filter/types';

const { t } = i18n.global;

export const RESERVE_STORAGE_KEY = '__diezv_reserve';

export const reservation = ref<Reservation>();
export const filteredValues = storage.get<FiltersForm>(FILTERS_STORAGE_KEY);

const currentValues = storage.get<StoredReserve>(RESERVE_STORAGE_KEY);

const startDateTime = computed<Date>(() => {
  if (!currentValues?.reservation_date || !currentValues?.start_time) {
    return new Date();
  }

  const date = new Date(currentValues.reservation_date);

  date.setUTCHours(currentValues.start_time.hours);
  date.setUTCMinutes(currentValues.start_time.minutes);
  date.setUTCSeconds(0);

  return date;
});

const endDateTime = computed<Date>(() => {
  if (!currentValues?.reservation_date && !currentValues?.end_time) {
    return new Date();
  }

  const date = new Date(currentValues.reservation_date);

  date.setUTCHours(currentValues.end_time.hours);
  date.setUTCMinutes(currentValues.end_time.minutes);
  date.setUTCSeconds(0);

  return date;
});

export const values = useForm<ReserveForm>({
  space_id: currentValues?.space_id,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  company_name: '',
  notes: '',
  products: [],
  theme_id: currentValues?.theme?.id ?? null,
  arrangement_id: null,
  estimated_persons: parseInt(currentValues?.persons ?? ''),
  start_date_time: startDateTime.value,
  end_date_time: endDateTime.value,
  terms: false,
  marketing: false,
});

const extraStepBasedOnTheme = computed(() =>
  currentValues?.theme !== null && currentValues?.theme?.has_arrangements
    ? [defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/2-ExtraOptions-1.vue')), defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/2-ExtraOptions-2.vue'))]
    : defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/2-ExtraOptions-1.vue')),
);

export const estimation = ref<{
  total: number;
  per_person: number;
  discount: number;
}>();

export const reserve = {
  async store() {
    try {
      const { data } = await api.post<Reservation>('/api/booking', values.form.value);

      if (!data) return;

      reservation.value = data;
      currentTab.value++;
    } catch (error) {
      // Switch to tab with error
      const ruleNumber = getRuleNumber(error);
      if (ruleNumber !== null) {
        switchToTab(ruleNumber);
      }
    }
  },
  async estimate() {
    try {
      const { data } = await api.post<{ total: number; per_person: number; discount: number }>('/api/estimate', values.form.value);
      estimation.value = data;
    } catch (error) {
      console.log(error);
    }
  },
};

export const tabs = shallowRef<Tab[]>([
  {
    tab: 0,
    title: t('Persoonlijke gegevens'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/1-Personal.vue')),
  },
  {
    tab: 1,
    title: t('Extra opties'),
    component: extraStepBasedOnTheme.value,
  },
  {
    tab: 2,
    title: t('Overzicht'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/4-Overview.vue')),
    button: t('Reserveren voltooien'),
    callback: reserve.store,
  },
  {
    tab: 3,
    title: t('Bevestiging'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/reserve/tabs/5-Confirmation.vue')),
    button: t('Reserveren voltooien'),
  },
]);

export const currentTab = ref<number>(0);
export const currentSubTab = ref<number>(0);

export const isSubTab = computed(() => Array.isArray(tabs.value[currentTab.value].component));

export function switchToTab(tabIndex: number) {
  currentTab.value = tabIndex;
  currentSubTab.value = 0;
}

export function goBack() {
  if (isSubTab.value && currentSubTab.value > 0) {
    currentSubTab.value--;
  } else if (currentTab.value > 0) {
    currentTab.value--;
  }
}

export async function goNext() {
  if (isSubTab.value && currentSubTab.value < tabs.value[currentTab.value].component.length - 1) {
    currentSubTab.value++;
  } else if (currentTab.value < tabs.value.length - 1) {
    if (!tabs.value[currentTab.value].callback) {
      currentTab.value++;
      return;
    }

    // @ts-expect-error
    tabs.value[currentTab.value]?.callback();
  }
}

const step_1 = ['first_name', 'last_name', 'email', 'phone_number', 'company_name'];
const step_2 = ['products'];
const step_3 = ['notes'];

const rules = shallowRef<Record<number, string[]>>({
  0: step_1,
  1: step_2,
  2: step_3,
});

function getRuleNumber(errors?: unknown) {
  if (!errors) return null;

  if (errors instanceof AxiosError) {
    values.setErrors(errors);

    for (const key in errors.response?.data.errors) {
      for (const ruleNumber in rules.value) {
        if (rules.value[ruleNumber].includes(key)) {
          return Number(ruleNumber);
        }
      }
    }
  }

  return null;
}
