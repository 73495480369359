<script setup lang="ts" generic="T">
  import type { Pagination } from '@/types/pagination';
  import { computed } from 'vue';

  const props = defineProps<{
    pagination: Pagination<T>;
    showPages: boolean;
    onPageChange: (page: number) => void;
  }>();

  const pages = computed(() => {
    const range: (string | number)[] = [];
    const total_pages = props.pagination.meta.last_page;
    const current_page = props.pagination.meta.current_page;

    if (total_pages <= 7) {
      for (let i = 1; i <= total_pages; i++) {
        range.push(i);
      }
    } else {
      if (current_page <= 3) {
        range.push(1, 2, 3, 4, '...', total_pages);
      } else if (current_page > total_pages - 3) {
        range.push(1, '...', total_pages - 3, total_pages - 2, total_pages - 1, total_pages);
      } else {
        range.push(1, '...', current_page - 1, current_page, current_page + 1, '...', total_pages);
      }
    }
    return range;
  });

  function goToPage(page: number | string) {
    if (typeof page === 'number' && page !== props.pagination.meta.current_page) {
      props.onPageChange(page);
    }
  }
</script>

<template>
  <nav v-if="pagination.meta.total > 0 && pagination.meta.total > pagination.meta.per_page" aria-label="Pagination Navigation">
    <div class="pagination">
      <div v-if="pagination.links.prev">
        <button class="button pagination" @click="goToPage(pagination.meta.current_page - 1)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>
        </button>
      </div>
      <div v-else>
        <button class="button pagination" disabled>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>
        </button>
      </div>

      <template v-if="showPages">
        <li v-for="page in pages" :key="page" :class="{ active: page === pagination.meta.current_page }" @click="goToPage(page)">
          <button v-if="page !== '...'">{{ page }}</button>
          <span v-else>...</span>
        </li>
      </template>

      <div v-if="pagination.links.next">
        <button class="button pagination" @click="goToPage(pagination.meta.current_page + 1)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </button>
      </div>
      <div v-else>
        <button class="button pagination" disabled>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </button>
      </div>
    </div>
  </nav>
</template>
