import { computed, ref, shallowRef, watch } from 'vue';

import { api } from '@/services/api';
import { useForm } from '@/services/form';
import { storage } from '@/services/storage';
import { removeEmpty } from '@/services/clear';
import { paramsObject } from '@/services/search';
import { type SearchResult } from './bar/desktop/components/Autocomplete';

import type { Pagination } from '@/types/pagination';
import type { Location } from '@/types/location';
import type { Create, DropDownValues, QueryForm } from './types';

export const FILTERS_STORAGE_KEY = '__diezv_filters';
export const searchQuery = ref(window.location.search);

const locations = shallowRef<Pagination<Location>>();
const inAvailableRegion = ref<boolean>(true);
const create = shallowRef<Create | null>(null);
const loading = ref(false);
const currentlyStoredFilters = storage.get<QueryForm>(FILTERS_STORAGE_KEY);
const excludedQueryParams = ['zip_code', 'latitude', 'longitude', 'facilities', 'range'];
const page = ref<number>(1);

const query = useForm<QueryForm>({
  location: undefined,
  zip_code: undefined,
  range: undefined,
  date: null,
  facilities: [],
  time_from: undefined,
  time_to: undefined,
  theme: undefined,
  persons: undefined,
  price_min: undefined,
  price_max: undefined,
  sort: undefined,
  latitude: null,
  longitude: null,
});

async function loadInitialFilters() {
  const searchParams = paramsObject.value;

  query.form.value = {
    location: searchParams.location || currentlyStoredFilters?.location || null,
    latitude: currentlyStoredFilters?.latitude || null,
    longitude: currentlyStoredFilters?.longitude || null,
    zip_code: currentlyStoredFilters?.zip_code || undefined,
    range: searchParams.range ? parseInt(searchParams.range) : currentlyStoredFilters?.range || undefined,
    date: searchParams.date || currentlyStoredFilters?.date || null,
    facilities: currentlyStoredFilters?.facilities ? Object.values(currentlyStoredFilters.facilities).map(Number) : [],
    time_from: searchParams.time_from || currentlyStoredFilters?.time_from || null,
    time_to: searchParams.time_to || currentlyStoredFilters?.time_to || null,
    theme: searchParams.theme ? parseInt(searchParams.theme) : currentlyStoredFilters?.theme || undefined,
    persons: searchParams.persons ? parseInt(searchParams.persons) : currentlyStoredFilters?.persons || undefined,
    price_min: searchParams.price_min ? parseInt(searchParams.price_min) : currentlyStoredFilters?.price_min || undefined,
    price_max: searchParams.price_max ? parseInt(searchParams.price_max) : currentlyStoredFilters?.price_max || undefined,
    sort: searchParams.sort || currentlyStoredFilters?.sort || null,
  };

  if (query.form.value.location) {
    await getCoordinates(query.form.value.location);
  }
}

async function getCoordinates(search: string) {
  const result = await api.post<SearchResult[]>('api/search', { search });
  if (!result) return;

  query.form.value.latitude = result.data[0].latitude;
  query.form.value.longitude = result.data[0].longitude;
}

function syncFilters() {
  updateQueryParams();
  updateStoredFilters();
}

function updateQueryParams() {
  const nonEmptyFilters = removeEmpty(query.form.value);
  const searchParams = new URLSearchParams();
  Object.entries(nonEmptyFilters).forEach(([key, value]) => {
    if (value !== undefined && value !== null && !excludedQueryParams.includes(key)) {
      searchParams.append(key, String(value));
    }
  });
  window.history.pushState({}, '', `?${searchParams.toString()}`);
}

function updateStoredFilters() {
  storage.remove(FILTERS_STORAGE_KEY);
  storage.set(FILTERS_STORAGE_KEY, removeEmpty(query.form.value));
}

const dropdown: DropDownValues = {
  theme: computed(() => create.value?.themes?.map(({ id, name }) => ({ name, value: id }))),
  arrangements: computed(() => create.value?.arrangements?.map(({ id, name }) => ({ name, value: id }))),
  sort: [
    { value: 'price-acs', name: 'Prijs laag - hoog' },
    { value: 'price-desc', name: 'Prijs hoog - laag' },
  ],
};

function setPage(newPage: number) {
  page.value = newPage;
  filter.search();
}

function resetPage() {
  page.value = 1;
}

const filter = {
  async search() {
    syncFilters();

    loading.value = true;

    try {
      const { data } = await api.post<Pagination<Location> & { in_available_region: boolean }>(`api/location?page=${page.value}`, removeEmpty(query.form.value));

      locations.value = data;
      inAvailableRegion.value = data.in_available_region;
      return true;
    } catch (error) {
      return false;
    } finally {
      loading.value = false;
    }
  },
  async static() {
    if (create.value?.arrangements) return;

    try {
      const { data } = await api.get<Create>('api/static');

      create.value = data;
    } catch (error) {
      console.error(error);
    }
  },
};

if (!create.value) filter.static();

loadInitialFilters();

watch(
  () => query.form,
  () => {
    resetPage();
    syncFilters();
  },
  { deep: true },
);

watch(
  () => query.form.value.sort,
  (newValue) => {
    if (newValue) {
      resetPage();
      filter.search();
    }
    syncFilters();
  },
  { deep: true },
);

export function useFilter() {
  return {
    filter,
    dropdown,
    locations,
    create,
    loading,
    query,
    inAvailableRegion,
    page,
    setPage,
    resetPage,
  };
}
