<script setup lang="ts">
  import { computed, onMounted, type Component } from 'vue';
  import type { Selected } from './types';
  import type { Location } from '@/types/location';
  import { useFilter } from '../../elements/filter';
  import { MapsModal, openMapsModal } from '../location/show';

  import { currentSubTab, currentTab, estimation, goBack, goNext, isSubTab, reserve, switchToTab, tabs, values } from '.';

  import Sidebar from './Sidebar.vue';

  const { create } = useFilter();

  const props = defineProps<{
    location: Location;
    supplierIndexRoute: string;
  }>();

  const selected: Selected = {
    theme: computed(() => create.value?.themes?.find(({ id }) => id === values.form.value.theme_id)),
    space: computed(() => props.location?.spaces?.find(({ id }) => id === values.form.value.space_id)),
    products: computed<Location['products']>(() =>
      Object.entries(props.location.products).reduce((result: Location['products'], [locationKey, products]) => {
        const filteredProducts = products.filter(({ id }) => values.form.value.products.includes(id));

        if (filteredProducts.length) {
          result[locationKey] = filteredProducts;
        }

        return result;
      }, {}),
    ),
    dateTime: computed(() => ({
      start_date: values.form.value.start_date_time,
      end_date: values.form.value.end_date_time,
    })),
  };

  onMounted(() => reserve.estimate());
</script>

<template>
  <template v-if="currentTab === tabs.length - 1">
    <component :is="tabs[tabs.length - 1].component" :values :location :supplier-index-route :selected></component>
  </template>
  <template v-else>
    <section class="section">
      <div class="container">
        <div class="block block--location-single">
          <div class="block__header">
            <h1 class="block__title">{{ location.name }}</h1>
          </div>
          <p class="block__location">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.185 19.103">
              <path fill="#662056" d="M1.004 9.543.96 9.377A6.873 6.873 0 1 1 14.237 5.82l.045.167c.895 3.343-1.989 9.51-3.187 11.86a.973.973 0 0 1-1.401.375c-2.213-1.436-7.794-5.335-8.69-8.678Z" />
              <path
                fill="#662056"
                d="M5.632.26a7.596 7.596 0 0 1 9.304 5.372l.044.166c.784 2.924-.763 7.517-3.24 12.376a1.696 1.696 0 0 1-2.44.654C4.725 15.858 1.09 12.655.305 9.73l-.044-.167A7.596 7.596 0 0 1 5.632.261Zm7.906 5.747A6.15 6.15 0 1 0 1.658 9.19l.045.166c.65 2.427 4.145 5.506 8.385 8.258a.25.25 0 0 0 .362-.097c2.297-4.503 3.783-8.917 3.133-11.344l-.045-.166Z"
              />
              <path fill="#FFF" d="M5.688 8.195A2 2 0 1 0 9.552 7.16l-.01-.036A2 2 0 1 0 5.678 8.16" />
              <path
                fill="#FFF"
                d="M6.905 5.011a2.723 2.723 0 0 1 3.336 1.927l.01.035a2.723 2.723 0 1 1-5.262 1.41l-.002-.017-.007-.02a2.723 2.723 0 0 1 1.76-3.285l.165-.05Zm1.938 2.3a1.276 1.276 0 1 0-2.466.661l.003.014.007.022a1.276 1.276 0 0 0 1.435.93l.128-.027a1.276 1.276 0 0 0 .902-1.565l-.009-.035Z"
              />
            </svg>
            {{ location.address }}
            <a href="javascript:void(0)" @click="openMapsModal()"> Toon op kaart </a>
            <MapsModal :longitude="location.longitude" :latitude="location.latitude"></MapsModal>
          </p>
        </div>
        <div class="block block--tabs">
          <ol>
            <template v-for="(tab, index) in tabs" :key="index">
              <template v-if="index !== tabs.length - 1">
                <li :class="{ active: currentTab === tab.tab }" @click="switchToTab(tab.tab)">
                  {{ tab.title }}
                </li>
              </template>
            </template>
          </ol>
        </div>
        <div class="grid grid--single">
          <div class="content">
            <form @submit.prevent="goNext()">
              <template v-if="isSubTab">
                <component :is="(tabs[currentTab].component as Component[])[currentSubTab]" :values :location :selected> </component>
              </template>
              <template v-else>
                <component :is="tabs[currentTab].component" :values :location :selected></component>
              </template>
              <hr />
              <div class="block block--buttons">
                <a href="javascript:void(0)" @click="goBack()" class="button outline" :class="{ invisible: tabs[currentTab].tab === 0 }"> Vorige </a>

                <button type="submit" class="button end">
                  {{ tabs[currentTab].button ? tabs[currentTab].button : 'Volgende' }}
                </button>
              </div>
            </form>
          </div>
          <Sidebar :values :location :selected :estimation />
        </div>
      </div>
    </section>
  </template>
</template>

<style scoped>
  .invisible {
    visibility: hidden;
  }
</style>
