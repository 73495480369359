<script setup lang="ts">
  import '@vuepic/vue-datepicker/dist/main.css';

  import { nl } from 'date-fns/locale';
  import { computed, reactive, ref, watch } from 'vue';
  import { debounce } from '@/services/debounce';

  import VueDatePicker from '@vuepic/vue-datepicker';
  import RadioSelect from '@/components/inputs/RadioSelect.vue';
  import type { DropdownOptions } from '@/types/dropdown';

  const date = defineModel<Date | any | null>('date', {
    set: (value) => {
      if (typeof value === 'string') {
        return new Date(value).toISOString().split('T')[0];
      }

      return value?.toISOString().split('T')[0];
    },
  });

  const timeFrom = defineModel<string | undefined>('timeFrom');
  const timeTo = defineModel<string | undefined>('timeTo');

  const pickerElement = ref<typeof VueDatePicker | null>();

  const time = reactive({
    from: false,
    to: false,
  });

  const allHoursInADay = Array(24)
    .fill(null)
    .map((_, i) => String(i + 1).padStart(2, '0') + ':00');

  const timeOptions = computed<DropdownOptions>(() => {
    return allHoursInADay.map((hour) => ({
      key: hour,
      value: hour,
    }));
  });

  const debounceInput = debounce((event: Event) => {
    date.value = (event.target as HTMLInputElement).value;
  }, 100);

  watch(date, () => {
    if (!date.value) {
      timeFrom.value = undefined;
      timeTo.value = undefined;
    }
  });
</script>

<template>
  <div class="block__item" @click="pickerElement?.toggleMenu()">
    <p class="block__label">Wanneer</p>
    <VueDatePicker
      ref="pickerElement"
      v-model="date"
      :config="{ closeOnAutoApply: false }"
      :auto-apply="true"
      :close-on-select="false"
      format="d MMMM yyyy"
      locale="nl-NL"
      :format-locale="nl"
      timezone="Europe/Amsterdam"
      :time-picker-inline="true"
      required
      :min-date="new Date()"
    >
      <template #dp-input="{ value }">
        <span v-if="!date" class="block__title">Selecteer een datum</span>
        <p v-else class="block__title date" v-bind="$attrs" @backspace="debounceInput($event)" placeholder="Selecteer een datum">
          {{ value }}
        </p>
      </template>
      <template #time-picker="">
        <div class="custom-time-picker-component">
          <div
            class="block__item"
            @click="
              time.from = !time.from;
              time.to = false;
            "
            v-outside="() => (time.from = false)"
          >
            <RadioSelect v-model:selected="timeFrom" :options="timeOptions" placeholder="09:00" />
          </div>
          <div
            class="block__item"
            @click="
              time.to = !time.to;
              time.from = false;
            "
            v-outside="() => (time.to = false)"
          >
            <RadioSelect v-model:selected="timeTo" :options="timeOptions" placeholder="17:00" />
          </div>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>

<style scoped lang="scss">
  .date {
    color: var(--Primary-500);
    font-weight: 500;
    cursor: pointer;

    &:empty:before {
      color: var(--Grey_400, #ddd);
      content: attr(placeholder);
      pointer-events: none;
      display: block;
    }

    &:focus {
      outline: 0px solid transparent;
    }
  }
</style>
