<script setup lang="ts" generic="T">
  import { useInput, type InputModel } from '@/services/input';
  import { computed } from 'vue';

  const value = defineModel<InputModel<T>>('value', { required: true });

  const props = defineProps<{
    name: string;
    label?: string;
    id?: string | number;
    icon?: boolean;
  }>();

  const { modifiedValue, forId, getError } = useInput(value, props);

  const error = computed(() => getError(value.value, props.name));
</script>

<template>
  <label v-bind="{ ...$attrs }" :for="forId" class="block__form__checkbox">
    <template v-if="icon">
      <slot></slot>
    </template>
    <template v-else>
      <span>
        <slot></slot>
      </span>
    </template>
    <input type="checkbox" :class="{ error }" v-model="modifiedValue" :name="name" :value="id" :id="forId" />
    <template v-if="label">{{ label }}</template>
    <span v-if="error">{{ error }}</span>
  </label>
</template>
