<script setup lang="ts">
  import { computed, ref, watch } from 'vue';
  import type { DropDownValues } from '../../../types';

  const theme = defineModel<number>('theme');
  const selected = defineModel<string | number>('selected');

  const props = defineProps<{ dropdown: DropDownValues['theme'] }>();

  const open = ref<boolean>(false);

  const dropdownOptions = computed(() => {
    if (!props.dropdown?.value) return [];

    return props.dropdown.value.map((item) => ({
      name: item?.name,
      id: item?.value,
    }));
  });

  function selectOption(id: number) {
    if (theme.value === id) {
      theme.value = undefined;
      selected.value = undefined;
      return;
    }

    theme.value = id;
    selected.value = dropdownOptions.value.find((item) => item.id === id)?.name;
  }

  watch(
    [theme, dropdownOptions],
    ([newTheme, newDropdownOptions]) => {
      if (newTheme && newDropdownOptions.length > 0) {
        selected.value = newDropdownOptions.find((item) => item.id === newTheme)?.name;
      }
    },
    { immediate: true },
  );
</script>

<template>
  <div class="block__item" :class="{ open: open }" @click="open = !open" v-outside="() => (open = false)">
    <p class="block__label noselect">Gelegenheid</p>
    <p class="block__title noselect" :class="{ active: selected }">
      {{ selected ?? 'Kies een gelegenheid' }}
    </p>
    <div class="block__dropdown select theme">
      <ul>
        <template v-for="item in dropdownOptions" :key="item">
          <li @click.prevent="selectOption(item.id)" :class="{ '-selected': theme === item.id }">{{ item.name }}</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .active {
    color: var(--Primary-500);
    font-weight: 500;
    cursor: pointer;
  }
</style>
