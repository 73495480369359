<script setup lang="ts" generic="T">
  import { onMounted, ref } from 'vue';

  const DEFAULT_PERSONS_VALUE = 0;

  const value = defineModel<number>('value', { required: false, default: DEFAULT_PERSONS_VALUE });
  const inputElement = ref<HTMLInputElement | null>(null);
  const persons = ref<boolean>(false);
  const incrementInterval = ref<ReturnType<typeof setInterval>>();

  const allowedKeys = ['Backspace', 'Tab', 'Escape', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'];

  function startIncrement() {
    value.value++;
    if (inputElement.value) {
      inputElement.value.innerHTML = (value.value + 1).toString();
    }

    incrementInterval.value = setInterval(() => {
      value.value++;
      if (inputElement.value) {
        inputElement.value.innerHTML = (value.value + 1).toString();
      }
    }, 100);
  }

  function stopIncrement() {
    clearInterval(incrementInterval.value);
    incrementInterval.value = undefined;
  }

  function startDecrement() {
    if (value.value === DEFAULT_PERSONS_VALUE) return;
    value.value--;
    if (inputElement.value) {
      inputElement.value.innerHTML = (value.value - 1).toString();
    }

    incrementInterval.value = setInterval(() => {
      if (value.value === DEFAULT_PERSONS_VALUE) return;
      value.value--;
      if (inputElement.value) {
        inputElement.value.innerHTML = (value.value - 1).toString();
      }
    }, 100);
  }

  function stopDecrement() {
    clearInterval(incrementInterval.value);
    incrementInterval.value = undefined;
  }

  function onInput(event: Event) {
    const target = event.target as HTMLInputElement;

    // Handle the <br> issue by clearing it if present
    if (target.innerHTML.trim() === '<br>') {
      target.innerHTML = ''; // Clear the content
    }

    // Update the search value
    value.value = Number(target.innerHTML);
    target.value = target.innerHTML;
  }

  onMounted(() => {
    if (value.value) {
      inputElement.value!.innerHTML = value.value.toString();
    }
  });
</script>

<template>
  <div class="block__item" @click="persons = !persons" v-outside="() => (persons = false)" :class="{ open: persons }">
    <p class="block__label">Aantal personen</p>
    <span ref="inputElement" class="block__title" contenteditable="true" tabindex="0" @keypress="!allowedKeys.includes($event.key) && $event.preventDefault()" @input="onInput" v-bind="$attrs"> </span>
    <div class="block__dropdown persons" @click.stop>
      <p class="block__text">Aantal personen</p>
      <div class="quantity noselect">
        <span @mousedown="startDecrement" @mouseup="stopDecrement" @mouseleave="stopDecrement" @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path fill="none" :stroke="value === 0 ? '#B3B3B3' : '#662056'" stroke-width="1.5" d="M16 .75C24.422.75 31.25 7.578 31.25 16S24.422 31.25 16 31.25.75 24.422.75 16 7.578.75 16 .75ZM11 16h10" />
          </svg>
        </span>
        <input v-model="value" value="0" type="number" min="1" name="persons" @click.stop style="transition: unset" />
        <span @mousedown="startIncrement" @mouseup="stopIncrement" @mouseleave="stopIncrement" @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
              fill="#662056"
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0Zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5Zm0 8.75a.75.75 0 0 1 .75.75v4.25H21a.75.75 0 1 1 0 1.5h-4.25V21a.75.75 0 1 1-1.5 0v-4.25H11a.75.75 0 1 1 0-1.5h4.25V11a.75.75 0 0 1 .75-.75Z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  [contenteditable] {
    color: var(--Primary-500);
    font-weight: 500;
    cursor: pointer;

    &:empty:before {
      color: var(--Grey_400, #ddd);
      content: attr(placeholder);
      pointer-events: none;
      display: block;
    }

    &:focus {
      outline: 0px solid transparent;
    }
  }
</style>
