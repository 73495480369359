import { ref, type ModelRef } from 'vue';
import type { QueryForm } from '../../../../types';
import type { UseForm } from '@/services/form';
import { api } from '@/services/api';
import { debounce } from '@/services/debounce';

export type SearchResult = {
  city: string;
  postcode: string;
  latitude: number;
  longitude: number;
};

export function useAutocomplete(query: ModelRef<UseForm<QueryForm>>) {
  const dropdownElementIsOpen = ref<boolean>(false);
  const activeSuggestionIndex = ref<number>(-1);
  const suggestions = ref<SearchResult[]>([]);

  async function searchLocation(search: string) {
    const result = await api.post<SearchResult[]>('api/search', { search });
    if (!result) return;

    dropdownElementIsOpen.value = true;
    suggestions.value = result.data;
  }

  function selectSuggestion(suggestion: SearchResult) {
    query.value.form.value.location = suggestion.city || '';
    query.value.form.value.latitude = suggestion.latitude;
    query.value.form.value.longitude = suggestion.longitude;
    query.value.form.value.zip_code = suggestion.postcode;

    dropdownElementIsOpen.value = false;
  }

  function onInput() {
    if (query.value.form.value.location) {
      debounceFetchSuggestions(query.value.form.value.location);
    } else {
      dropdownElementIsOpen.value = false;
      suggestions.value = [];
      query.value.form.value.latitude = null;
      query.value.form.value.longitude = null;
      query.value.form.value.zip_code = undefined;
    }
  }

  const debounceFetchSuggestions = debounce((input: string) => {
    searchLocation(input);
  }, 200);

  const onArrowDown = () => {
    if (activeSuggestionIndex.value < suggestions.value.length - 1) {
      activeSuggestionIndex.value++;
    } else {
      activeSuggestionIndex.value = 0; // Loop back to the top
    }
  };

  const onArrowUp = () => {
    if (activeSuggestionIndex.value > 0) {
      activeSuggestionIndex.value--;
    } else {
      activeSuggestionIndex.value = suggestions.value.length - 1; // Loop back to the bottom
    }
  };

  const onEnter = (event: KeyboardEvent) => {
    event.preventDefault();
    if (activeSuggestionIndex.value !== -1) {
      selectSuggestion(suggestions.value[activeSuggestionIndex.value]);
    }
  };

  function onBlur() {
    setTimeout(() => (dropdownElementIsOpen.value = false), 100);
  }

  return {
    dropdownElementIsOpen,
    activeSuggestionIndex,
    suggestions,
    query,
    selectSuggestion,
    onInput,
    onArrowDown,
    onArrowUp,
    onEnter,
    onBlur,
  };
}
