<script setup lang="ts">
  import '@vuepic/vue-datepicker/dist/main.css';

  import { computed, onMounted, reactive, ref, watch } from 'vue';

  import { api } from '@/services/api';
  import { euro } from '@/services/currency';
  import { useForm } from '@/services/form';
  import { storage } from '@/services/storage';
  import { dialog } from '@/components/modal/modal';

  import { FILTERS_STORAGE_KEY } from './filter';
  import { RESERVE_STORAGE_KEY } from '../pages/reserve';

  import CaptchaButton from '@/components/google/captcha/Button.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';

  import type { Location, Space, Theme } from '@/types/location';
  import { formatHoursMinutes } from '@/services/date';
  import { getError } from '@/services/input';
  import type { QueryForm } from './filter/types';

  const [SelectSpaceModal, showSelectSpaceModal, closeSelectSpaceModal] = dialog(() => import('#website/pages/location/show/components/space/Select.vue'));

  const props = defineProps<{ location: Location; route: string }>();
  const previousSelectedValues = storage.get<QueryForm>(FILTERS_STORAGE_KEY);
  const prices = ref<{ total: number; per_person: number; discount: number }>({ total: 0, per_person: 0, discount: 0 });
  const availableSpaces = ref<Space[]>([]);
  const isFetchingSpaces = ref<boolean>(false);

  const startFromTimePicker = ref({
    hours: new Date().getHours() + 1,
    minutes: 0,
  });

  const startEndTimePicker = ref({
    hours: startFromTimePicker.value.hours + 5,
    minutes: startFromTimePicker.value.minutes,
  });

  const format = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const errors = reactive<{
    space_id: string | null;
    reservation_date: string | null;
    persons: string | null;
    time_from: string | null;
    time_to: string | null;
  }>({
    space_id: null,
    persons: null,
    reservation_date: null,
    time_from: null,
    time_to: null,
  });

  const values = useForm<{
    space_id: number | null;
    persons: number;
    reservation_date: Date;
    start_time: { hours: number; minutes: number };
    end_time: { hours: number; minutes: number };
    theme: number | null;
  }>({
    space_id: null,
    persons: previousSelectedValues?.persons || 1,
    reservation_date: new Date(previousSelectedValues?.date ?? new Date()),
    start_time: startFromTimePicker.value,
    end_time: startEndTimePicker.value,
    theme: previousSelectedValues?.theme ?? null,
  });

  const selectedSpace = computed(() => {
    if (!values.form.value.space_id) return null;

    return props.location.spaces.find((space) => space.id === values.form.value.space_id);
  });

  const dateTimeFrom = computed(() => {
    if (!values.form.value.start_time) {
      return null;
    }

    const date = new Date(values.form.value.reservation_date);

    date.setUTCHours(values.form.value.start_time.hours);
    date.setUTCMinutes(values.form.value.start_time.minutes);
    date.setUTCSeconds(0);

    return date;
  });

  const dateTimeTo = computed(() => {
    if (!values.form.value.end_time) {
      return null;
    }

    const date = new Date(values.form.value.reservation_date);

    date.setUTCHours(values.form.value.end_time.hours);
    date.setUTCMinutes(values.form.value.end_time.minutes);
    date.setUTCSeconds(0);

    return date;
  });

  async function submit(token: string) {
    if (!values.form.value.space_id) {
      errors.space_id = 'Dit veld is verplicht';
      return;
    }

    if (!values.form.value.reservation_date) {
      errors.reservation_date = 'Dit veld is verplicht';
      return;
    }

    if (!values.form.value.start_time) {
      errors.time_from = 'Dit veld is verplicht';
      return;
    }

    if (!values.form.value.end_time) {
      errors.time_to = 'Dit veld is verplicht';
      return;
    }

    if (!values.form.value.persons) {
      errors.persons = 'Dit veld is verplicht';
      return;
    }

    let theme = null;

    if (values.form.value.theme) {
      const { data } = await api.get<Theme>(`api/theme/${values.form.value.theme}`);
      if (data) theme = data;
    }

    const valuesToStore = { ...values.form.value, theme: theme ?? values.form.value.theme ?? null };

    storage.set(RESERVE_STORAGE_KEY, valuesToStore);

    try {
      const url = new URL(props.route);
      url.searchParams.append('g-recaptcha-response', token);
      window.location.href = url.toString();
    } catch (error) {
      console.warn(error);
    }
  }

  async function estimatePrice() {
    if (!values.form.value.space_id || !dateTimeFrom.value || !dateTimeTo.value) {
      return;
    }

    try {
      const { data } = await api.post<{ total: number; per_person: number; discount: number }>('api/estimate', {
        space_id: values.form.value.space_id,
        start_date_time: dateTimeFrom.value,
        end_date_time: dateTimeTo.value,
        estimated_persons: values.form.value.persons,
      });

      prices.value = data;
    } catch (error) {
      if (!selectedSpace.value) return;
      // calculate price manually
      prices.value = {
        total: selectedSpace.value?.price_fixed_min + selectedSpace.value?.price_per_person * values.form.value.persons,
        per_person: selectedSpace.value?.price_per_person,
        discount: 0,
      };
    }
  }

  async function getAvailableSpaces() {
    isFetchingSpaces.value = true;
    availableSpaces.value = [];
    if (!props.location.id || !values.form.value.persons || !values.form.value.start_time || !values.form.value.end_time) return;

    const currentTime = new Date();
    const selectedTime = new Date(values.form.value.reservation_date);

    selectedTime.setHours(values.form.value.start_time.hours);
    selectedTime.setMinutes(values.form.value.start_time.minutes);

    if (selectedTime < currentTime) {
      availableSpaces.value = [];
      return;
    }

    try {
      availableSpaces.value = [];

      const { data } = await api.get<Space[]>(`api/location/${props.location.id}/spaces/available`, {
        params: {
          from: dateTimeFrom.value,
          to: dateTimeTo.value,
          persons: values.form.value.persons,
        },
      });

      availableSpaces.value = data;
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingSpaces.value = false;
    }
  }

  function formattedDate() {
    return values.form.value.reservation_date ? values.form.value.reservation_date.toLocaleDateString() : 'Kies een datum';
  }

  watch(values.form.value, estimatePrice, { immediate: true });
  watch(
    () => [values.form.value.start_time, values.form.value.end_time, values.form.value.reservation_date, values.form.value.persons],
    async () => {
      await getAvailableSpaces();

      if (!availableSpaces.value.find((space) => space.id === values.form.value.space_id)) {
        values.form.value.space_id = null;
      }
    },
  );

  onMounted(() => {
    getAvailableSpaces();
  });
</script>

<template>
  <div class="block--sidebar block">
    <div class="block__content">
      <h2 class="block__title">Boek nu deze locatie</h2>
      <div class="block__form">
        <label for="persons">Aantal personen</label>
        <input type="number" :class="{ error: errors.persons }" :min="16" placeholder="Aantal personen" name="persons" v-model="values.form.value.persons" />
        <span class="error__field" v-if="errors.persons">{{ errors.persons }}</span>
      </div>
      <div class="block__form">
        <label for="date-select">Datum</label>
        <VueDatePicker ref="pickerElement" v-model="values.form.value.reservation_date" placeholder="Kies een datum" locale="nl-NL" timezone="Europe/Amsterdam" :format="format" :min-date="new Date()" auto-apply :enableTimePicker="false">
          <template #trigger>
            <div class="block__form">
              <input type="text" placeholder="Selecteer een datum" :class="{ error: errors.reservation_date }" name="date-select" :value="formattedDate()" readonly />
            </div>
          </template>
        </VueDatePicker>
        <span class="error__field" v-if="errors.reservation_date">{{ errors.reservation_date }}</span>
      </div>
      <div class="block__form">
        <label for="time-from">Tijd</label>
        <div class="grid grid--two">
          <div>
            <VueDatePicker
              ref="timeFromPicker"
              v-model="values.form.value.start_time"
              placeholder="Start tijd"
              locale="nl-NL"
              timezone="Europe/Amsterdam"
              auto-apply
              time-picker
              minutes-increment="15"
              no-hours-overlay
              no-minutes-overlay
              :start-time="startFromTimePicker"
            >
              <template #trigger>
                <div class="block__form">
                  <input type="text" placeholder="Selecteer een tijd" name="time-from" :class="{ error: getError(values, 'start_time') }" :value="formatHoursMinutes(values.form.value.start_time)" readonly />
                </div>
              </template>
            </VueDatePicker>
            <span class="error__field" v-if="errors.time_from">{{ errors.time_from }}</span>
          </div>
          <div>
            <VueDatePicker
              ref="timeToPicker"
              v-model="values.form.value.end_time"
              placeholder="Eind tijd"
              locale="nl-NL"
              timezone="Europe/Amsterdam"
              auto-apply
              time-picker
              minutes-increment="15"
              no-hours-overlay
              no-minutes-overlay
              :start-time="startEndTimePicker"
            >
              <template #trigger>
                <div class="block__form">
                  <input type="text" placeholder="Selecteer een tijd" name="time-from" :class="{ error: getError(values, 'end_time') }" :value="formatHoursMinutes(values.form.value.end_time)" readonly />
                </div>
              </template>
            </VueDatePicker>
            <span class="error__field" v-if="errors.time_to">{{ errors.time_to }}</span>
          </div>
        </div>
      </div>
      <div v-if="!isFetchingSpaces && availableSpaces.length > 0" class="block__form">
        <label for="">Selecteer de ruimte</label>
        <input type="text" :value="selectedSpace?.name" readonly @click="showSelectSpaceModal()" placeholder="Selecteer de ruimte" />
        <span class="error__field" v-if="errors.space_id">{{ errors.space_id }}</span>
      </div>
      <p v-else-if="!isFetchingSpaces" class="block__text alt">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 19.5">
          <path
            fill="#662056"
            d="M9.75 0c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S0 15.135 0 9.75 4.365 0 9.75 0Zm0 1.5a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5Zm-.004 11.25a1 1 0 1 1 .009 2 1 1 0 0 1-.009-2ZM9.75 4.5a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75Z"
          />
        </svg>
        Er zijn geen ruimtes beschikbaar voor de geselecteerde datum en tijd.
      </p>
    </div>
    <div class="block__footer">
      <hr />
      <template v-if="selectedSpace">
        <ul>
          <li>
            <span>Kosten zaalhuur</span>
            <span>{{ euro.format(selectedSpace.price_fixed_min) }}</span>
          </li>
          <li>
            <span>{{ euro.format(selectedSpace.price_per_person) }} x {{ values.form.value.persons }} personen </span>
            <span>{{ euro.format(prices.per_person * values.form.value.persons) }}</span>
          </li>

          <li v-if="prices.discount > 0">
            <span> Korting </span>
            <span>- {{ euro.format(prices.discount) }}</span>
          </li>
          <li>
            <span>Totaal</span>
            <span>{{ euro.format(prices.total) }}</span>
          </li>
        </ul>
      </template>
      <CaptchaButton v-if="availableSpaces.length > 0 && selectedSpace" :callback="submit">Reservering aanvragen</CaptchaButton>
      <button class="button" disabled v-else>Reservering aanvragen</button>
    </div>
    <SelectSpaceModal
      class="block block--modal"
      :location="location"
      :available-spaces="availableSpaces"
      :date-time-from="dateTimeFrom"
      :date-time-to="dateTimeTo"
      :persons="values.form.value.persons"
      :current-selected-space-id="values.form.value.space_id"
      @close="closeSelectSpaceModal()"
      @selected-space="(value: number | undefined) => (value !== undefined ? (values.form.value.space_id = value) : '', closeSelectSpaceModal())"
    />
  </div>
</template>

<style>
  .w-100 {
    width: 100%;
  }
</style>
