import { dialog } from '@/components/modal/modal';
import { api } from '@/services/api';
import { errorHandler } from '@/services/form';
import type { Theme } from '@/types/location';
import type { Pagination } from '@/types/pagination';
import type { Service } from '@/types/service';
import type { Supplier } from '@/types/suppliers';
import { ref, shallowRef } from 'vue';

export const [FilterSuppliersModal, openFilterModal, closeFilterModal] = dialog(() => import('../../components/modals/FilterSuppliersModal.vue'));
const suppliers = shallowRef<Pagination<Supplier>>();

export type SupplierFilter = {
  themes: Theme[];
  services: Service[];
  max_radius: number;
  latitude?: number;
  longitude?: number;
  zip_code?: string;
  count: number;
};

export type ServiceResponse = {
  featured: Service[];
  services: Service[];
};
export const supplierCount = ref(0);
const page = ref<number>(1);

function setPage(newPage: number) {
  page.value = newPage;
}

function resetPage() {
  page.value = 1;
}

const filter = {
  async loadSuppliers(filter: SupplierFilter) {
    try {
      const response = await api.post<Pagination<Supplier>>(`/api/suppliers?page=${page.value}`, filter);
      supplierCount.value = response.data.meta.total ?? 0;
      suppliers.value = response.data;
    } catch (error) {
      errorHandler(error);
    }
  },
};

export async function getThemes() {
  return await api.get<Theme[]>('/api/themes');
}

export async function getServices() {
  return await api.get<ServiceResponse>('/api/services');
}

export function useSuppliers() {
  return { suppliers, filter, setPage, resetPage };
}
