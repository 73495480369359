<script setup lang="ts">
  import { useFilter } from '../../elements/filter';
  import { nextTick } from 'vue';

  import type { Theme } from '../../elements/filter/types';

  const { filter, query } = useFilter();

  defineProps<{ themes?: Theme[] }>();

  async function addSearchParam(theme: Theme) {
    query.form.value.theme = theme.id;
    await nextTick();
    filter.search();

    if (window.location.pathname === '/') {
      window.location.href = '/locatie';
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
</script>

<template>
  <section class="section green overflow">
    <div class="container">
      <template v-if="themes?.length">
        <div class="section__header">
          <h2 class="section__title">Waar zoek je een locatie voor?</h2>
        </div>
        <div class="grid grid--six slider">
          <template v-for="(item, index) in themes" :key="index">
            <a href="#" class="block block--cat" @click="addSearchParam(item)">
              <div class="block__content" v-html="item.icon_svg"></div>
              <p class="block__text">
                {{ item.name }}
              </p>
            </a>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="block block--location">
          <div class="block__wrapper">
            <p>Geen thema's gevonden</p>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>
