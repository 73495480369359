<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';
  import { FilterSuppliersModal, getServices, getThemes, openFilterModal, useSuppliers, type SupplierFilter } from '.';
  import { useForm } from '@/services/form';
  import type { Service } from '@/types/service';
  import type { Theme } from '@/types/location';
  import Checkbox from '@/components/inputs/Checkbox.vue';
  import Pagination from '@/components/Pagination.vue';

  const themes = ref<Theme[]>([]);
  const services = ref<Service[]>([]);
  const featuredServices = ref<Service[]>([]);
  const { filter, suppliers, setPage, resetPage } = useSuppliers();

  const supplierFilters = useForm<SupplierFilter>({
    themes: [],
    services: [],
    max_radius: 10,
    latitude: undefined,
    longitude: undefined,
    zip_code: '',
    count: 0,
  });

  async function fetchThemes() {
    const result = await getThemes();

    if (!result) return;

    themes.value = result.data;
  }

  async function fetchServices() {
    const result = await getServices();

    if (!result.data) return;

    featuredServices.value = result.data.featured;
    services.value = result.data.services;
  }

  watch([() => supplierFilters.form.value.themes, () => supplierFilters.form.value.services, () => supplierFilters.form.value.longitude, () => supplierFilters.form.value.latitude, () => supplierFilters.form.value.max_radius], () => {
    resetPage();
    filter.loadSuppliers(supplierFilters.form.value);
  });

  onMounted(() => {
    filter.loadSuppliers(supplierFilters.form.value);
    fetchThemes();
    fetchServices();
  });
</script>
<template>
  <section class="section">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">Leveranciers</h2>
        <div class="section__filters">
          <div class="section__filter">
            <a @click="openFilterModal()" href="#" class="button filters">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                <path
                  fill="#202020"
                  d="M2.083 0c.886 0 1.643.553 1.944 1.333h8.723a.75.75 0 1 1 0 1.5l-8.723.001A2.084 2.084 0 1 1 2.083 0Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167ZM6.75 4.667c.886 0 1.643.553 1.944 1.333h4.056a.75.75 0 1 1 0 1.5H8.694a2.084 2.084 0 0 1-3.888 0H.75a.75.75 0 0 1 0-1.5h4.055A2.084 2.084 0 0 1 6.75 4.667Zm0 1.5a.583.583 0 1 0 0 1.166.583.583 0 0 0 0-1.166Zm4.667 3.166a2.083 2.083 0 1 1-1.944 2.834H.75a.75.75 0 1 1 0-1.5l8.723-.001a2.084 2.084 0 0 1 1.944-1.333Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167Z"
                />
              </svg>
              Filters
            </a>
          </div>
        </div>
      </div>
      <div class="grid--sidebar grid">
        <div class="sidebar">
          <div class="margin block">
            <div class="block__form">
              <label>Gelegenheid</label>
              <ul>
                <template v-for="theme in themes" v-bind:key="theme.id">
                  <li>
                    <label class="block__form__checkbox">
                      <Checkbox v-model:value="supplierFilters" name="themes" :id="theme.id" class="supplier" />
                      {{ theme.name }}
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="margin block">
            <div class="block__form">
              <label>Diensten</label>
              <ul>
                <template v-for="featuredService in featuredServices" v-bind:key="featuredService.id">
                  <li>
                    <label class="block__form__checkbox">
                      <Checkbox v-model:value="supplierFilters" name="services" :id="featuredService.id" class="supplier" />
                      {{ featuredService.name }}
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="grid--three grid">
            <template v-for="supplier in suppliers?.data" v-bind:key="supplier.id">
              <a :href="`/leverancier/${supplier.id}`" class="block--leverancier block">
                <img :src="supplier.media?.[0]?.url" :alt="supplier.name" class="block__img" />
                <div class="block__content">
                  <div class="block__header">
                    <p class="block__title">
                      {{ supplier.name }}
                    </p>
                  </div>
                  <p class="block__text">
                    {{ supplier.city }}
                  </p>
                </div>
              </a>
            </template>
          </div>
          <Pagination v-if="suppliers" :pagination="suppliers" :show-pages="false" :on-page-change="(page: number) => (setPage(page), filter.loadSuppliers(supplierFilters.form.value))" />
        </div>
      </div>
    </div>
  </section>
  <FilterSuppliersModal v-model:values="supplierFilters" :services="services" :suppliers="suppliers" />
</template>
